/* latin-ext */
@font-face {
  font-family: '__Varela_53ebeb';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/2e4cb8b14ddfe623-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Varela_53ebeb';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/ad6deedd8a3e0a2d-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Varela_Fallback_53ebeb';src: local("Arial");ascent-override: 94.47%;descent-override: 26.52%;line-gap-override: 0.00%;size-adjust: 107.45%
}.__className_53ebeb {font-family: '__Varela_53ebeb', '__Varela_Fallback_53ebeb', sans-serif;font-weight: 400;font-style: normal
}

@font-face {
font-family: '__satoshi_5ab76a';
src: url(/_next/static/media/d9396795aa5ec363-s.p.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: '__satoshi_Fallback_5ab76a';src: local("Arial");ascent-override: 92.36%;descent-override: 21.95%;line-gap-override: 9.14%;size-adjust: 109.35%
}.__className_5ab76a {font-family: '__satoshi_5ab76a', '__satoshi_Fallback_5ab76a'
}.__variable_5ab76a {--font-sans: '__satoshi_5ab76a', '__satoshi_Fallback_5ab76a'
}

